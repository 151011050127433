@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: spacegro;
  src: url(assets/fonts/SpaceGrotesk.ttf);
}


@layer base {
  * {
    @apply box-border p-0 m-0 list-none outline-none font-spacegro;
  }
  :root {
    --pl-primary: #02113a;
    --pl-dark: #111111;
    --pl-dark-1d: #1D1D1F;
    --pl-darkgray: #333333;
    --pl-gray-66: #666666;
    --pl-white: #ffffff;
    --pl-green: #269354;
    --pl-bordergray: #E8E8E8;
  }

  html {
    @apply text-[62.5%] max-3xl:text-[48%] max-2xl:text-[43%] max-xl:text-[45%] max-md:text-[50%] max-xs:text-[43%] scroll-smooth;
  }

  body {
    @apply max-w-full overflow-x-hidden;
  }

  img,
  svg {
    width: 100%;
    height: 100%;
  }
}

@layer base {
  ul, ol {
    list-style: revert;
  }
}

@layer components {
  .border-red{
    @apply border border-red-600
  }
  .border-blue{
    @apply border border-blue-600
  }
  .page-y-width{
    @apply w-[77%] max-xl:w-[85%]
  }
  .section-y-space{
    @apply py-[10rem]
  }
  .cursor{
    @apply fixed left-0 top-0 w-[130px] h-[130px] z-[999999] pointer-events-none;
  }
  .cursor-inner{
    @apply opacity-0 bg-[#000000] bg-opacity-50 rounded-[50%];
  }
  .cursor.excactive{
    @apply mix-blend-difference;
  }
  .cursor.excactive .cursor-inner {
    @apply bg-white;
  }
  .readmore-cursor{
    @apply text-pl-white text-[1.8rem] z-[999] cursor-default;
  }
  .cursor.excactive .readmore-cursor {
    @apply text-pl-dark;
  }
}
.blue-gradient{
  background: linear-gradient(180deg, #009FE8 0%, #005982 100%);
}
.orange-gradient{
  background: linear-gradient(180deg, #F4830A 0%, #B45F03 100%);
}
.lightgray-gradient{
  background: linear-gradient(180deg, #576264 0%, #2D3742 100%);
}
.pink-gradient{
  background: linear-gradient(180deg, #E1AEBF 0%, #231826 100%);
}
.gold-gradient{
  background: linear-gradient(180deg, #0D161D 0%, #FDEBBA 100%);
}
.darkorange-gradient{
  background: linear-gradient(180deg, #A93900 0%, #5C1F03 100%);
}
.teal-gradient{
  background: linear-gradient(180deg, #477170 0%, #497E6C 100%);
}
.brown-gradient{
  background: linear-gradient(180deg, #463930 0%, #3B2419 100%);
}
.darkblue-gradient{
  background: linear-gradient(180deg, #197CB4 0%, #030B16 100%);
}
.darkgray-gradient{
  background: linear-gradient(180deg, #282827 0%, #444A51 100%);
}
.dark-gradient{
  background: linear-gradient(180deg, #434343 0%, #000000 100%);
}
.services-gradient{
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 6.8%, #039EEF 35.3%, #EF28BD 63.3%, rgba(255, 255, 255, 0) 90.3%);
}

.cursor.active .cursor-inner {
  opacity: 1;
  animation: cursor-enter 0.4s;
}

@keyframes cursor-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
  100% {
    opacity: 0;
  }
}
@keyframes cursor-enter {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.case-studies-gradient::after{
  content: "";
  filter: blur(10rem);
  height: 50vh;
  display: block;
  position: absolute;
  width: 30%;
  top: 18%;
  left: -10%;
  background: conic-gradient(from 6.35rad,#009fe8a9,#0059829f);
  filter: blur(10rem);
  z-index: 0;
}
.floating-menu-bg{
  -webkit-backdrop-filter: blur(.5625rem);
    backdrop-filter: blur(.5625rem);
    background-color: #0d0d0d80;
}
.play-video-btn {
  display: block;
  height: auto;
}

.play-video-btn .play-video-outer {
  transform-origin: 50% 50%;
  transition: all .5s cubic-bezier(.68,-.55,.27,1.55);
}
.play-video-btn:hover .play-video-outer {
 transform: scale(1.05);
}

.play-video-btn .play-video-spin {
  animation: play-video-rotate 20s linear infinite;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: play-video-rotate;
  animation-timeline: auto;
  animation-range-start: normal;
  animation-range-end: normal;
  transform-origin: 50% 50%;
}
.play-video-btn:hover .play-video-spin {
  animation: play-video-rotate-reverse 5s linear infinite;
  animation-duration: 5s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: play-video-rotate-reverse;
    animation-timeline: auto;
    animation-range-start: normal;
  transform-origin: 50% 50%;
}
@keyframes play-video-rotate{
  0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(1turn);
}
}
@keyframes play-video-rotate-reverse{
  0% {
    transform: rotate(1turn);
  }
  100% {
  transform: rotate(0deg);
}
}
.about-us-banner{
  background-image: url(assets/images/about_bg.webp);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
.our-solutions-bg{
  /* z-index: 0; */
  background-image: url(assets/images/66974e7d470a4544cbe906ed_bg.webp);
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: 100% 140%;
  background-attachment: fixed;
  /* border-top-left-radius: 3.125rem;
  border-top-right-radius: 3.125rem; */
  margin-top: -3rem;
  /* position: relative; */
}

.mySwiper .swiper-slide {
  width: auto !important;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.mySwiper .swiper-wrapper{
  transition-timing-function : linear;
}
.rel_links-anchor{
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
}
/* inset: 0% auto auto 0%; */

.custom-cursor {
  /* cursor: url('assets/images/cursor-left.png'), auto; */
}
.button {
  display: inline-grid;
  border: 1px solid #000;
  border-radius: 50px;
  padding: 10px;
  width: 150px;
  margin: 15px 5px;
  text-decoration: none;
  text-align: center;
  color: #000;
  overflow: hidden;
}